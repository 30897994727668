import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { convertAutocompleteItemToApi } from "@monorepo/utils/src/api/convertFiltersToApi";

export const convertFiltersCustomToApi = (
  resultFilters: ITableFiltersObject & { fieldFilters: { creationDateFrom?: string; creationDateTo?: string; status?: string } }
): void => {
  convertAutocompleteItemToApi(resultFilters, "login", `fieldFilters.login.identityName`);
  if (resultFilters.sort?.["fund"]) {
    resultFilters.sort["fundCode"] = resultFilters.sort["fund"];
    delete resultFilters.sort["fund"];
  }
};
