


























import { defineComponent } from "@vue/composition-api";
import { tabsTypes } from "@monorepo/utils/src/variables/projectsData/systemAccesses/tabsTypes";
import AddElement from "@monorepo/uikit/src/components/tableViews/AddElement.vue";
import eventBus from "@monorepo/utils/src/eventBus";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { mapGetters } from "vuex";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import { educationIds } from "@monorepo/utils/src/variables/educationIds";

export default defineComponent({
  name: "Tabs",
  model: {
    prop: "value",
    event: "change",
  },
  components: {
    AddElement,
  },
  props: {
    value: {
      type: String,
    },
  },
  data() {
    return {
      tabsTypes,
      educationIds,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    showNewUserButton(): boolean {
      return this.$route?.params?.tab === this.tabsTypes.users.url;
    },
    tabsTypesQty(): number {
      return Object.keys(this.tabsTypes).length;
    },
    isCanCreate(): boolean {
      return isAuthorityExist(this.user, authorities.USER_CREATE);
    },
  },
  methods: {
    AddNewUser() {
      eventBus.$emit("ADD_NEW_USER", true);
    },
  },
});
