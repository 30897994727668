





























import Breadcrumbs from "@monorepo/uikit/src/components/common/Breadcrumbs.vue";
import { defineComponent } from "@vue/composition-api";
import { module as ExternalAPIUsersView } from "@monorepo/informationSecurity/src/store/modules/externalAPIUsers";
import useInitTableStoreModule from "@monorepo/utils/src/store/composables/useInitTableStoreModule";
import { breadcrumbs } from "@monorepo/informationSecurity/src/views/ExternalAPIUsers/constants/breadcrumbs";
import { viewTitle } from "@monorepo/utils/src/variables/projectsData/externalApi/viewTitle";
import AddElement from "@monorepo/uikit/src/components/tableViews/AddElement.vue";
import { mapGetters, Module } from "vuex";
import useGetTableLibs from "@monorepo/informationSecurity/src/views/ExternalAPIUsers/composables/useGetTableLibs";
import Tabs from "@monorepo/informationSecurity/src/views/ExternalAPIUsers/components/Tabs.vue";
import eventBus from "@monorepo/utils/src/eventBus";
import { educationIds } from "@monorepo/utils/src/variables/educationIds";

export default defineComponent({
  name: "SystemAccessesView",
  components: {
    Breadcrumbs,
    AddElement,
    Tabs,
    // roles: () =>
    //   import(
    //     /* webpackChunkName: "SystemAccessesRolesTab" */
    //     "@monorepo/informationSecurity/src/views/SystemAccesses/components/roles/Roles.vue"
    //   ),
    users: () =>
      import(
        /* webpackChunkName: "ExternalAPIUsersUsersTab" */
        "@monorepo/informationSecurity/src/views/ExternalAPIUsers/components/users/Users.vue"
      ),
  },
  // props: {
  //   roleId: {
  //     type: String,
  //     default: "",
  //   },
  // },
  data() {
    return {
      tab: "users",
      breadcrumbs,
      viewTitle,
      educationIds,
    };
  },
  computed: {
    ...mapGetters("auth", ["isOpenEducation"]),
    ...mapGetters("externalAPIUsers", ["isTableLoading"]),
    ...mapGetters("externalAPIUsers", { openedId: "openedUserId" }),
    isShowModal() {
      return !this.isOpenEducation && Boolean(this.openedId);
    },
  },
  methods: {
    addNewUser() {
      eventBus.$emit("ADD_NEW_USER", true);
    },
  },
  setup(props, { root }) {
    useInitTableStoreModule(root, "externalAPIUsers", ExternalAPIUsersView as Module<unknown, unknown>);
    useGetTableLibs(root);
  },
});
